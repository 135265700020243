<template>
	<div class="register-model" v-if="show">
		<!-- 弹框  -->
		<van-popup :close-on-click-overlay="false" :round="false" :overlay="true"
			:overlay-style="{background:'rgba(0,0,0,0.3)'}" :style="{ background: 'rgba(0,0,0,0)' }"
			v-model.sync="show">
			<div class="model-view" :class="[ismask?'mask':'']" v-if="langInfo">
				<van-tabs v-model="current" background="transparent" color="#245BD9" title-active-color="#245BD9"
					:title-inactive-color="ismask?'#000000':'#FFFFFF'">
					<van-tab :title="langInfo.newLangInfo.loginModel.email">
						<div class="item-value">
							<!-- 输入 邮箱 电话 -->
							<input v-model="Email" class="value-input"
								:placeholder="langInfo.newLangInfo.loginModel.email" @focus="showTips = ''">
						</div>
					</van-tab>
					<van-tab :title="langInfo.newLangInfo.loginModel.phone">
						<div class="item-value flex">
							<span class="tips">+{{ showDataInfo.AreaCode }}</span>
							<!-- 输入 电话 -->
							<input v-model="Phone" class="value-input"
								:placeholder="langInfo.newLangInfo.loginModel.phone" @focus="showTips = ''">
						</div>
					</van-tab>
				</van-tabs>
				<div class="model-tips" v-if="showTips">
					{{showTips}}
				</div>
				<!-- 登录 -->
				<div class="model-button" @click="handClick">
					{{langInfo.newLangInfo.loginModel.submit}}
				</div>

				<div class="model-link" :style="{color:!ismask?'#fff':'#333'}" @click="handSign">
					{{langInfo.newLangInfo.registerPage.signup}}
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		List
	} from '../../util/en_config_new';
	import {
		langData
	} from '../../util/lang_config_new';
	import {
		Popup,
		Toast,
		Tab,
		Tabs,
		Dialog
	} from 'vant';
	export default {
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			ismask: {
				type: Boolean,
				default: false,
			}
		},
		components: {
			[Popup.name]: Popup,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
		},
		data() {
			return {
				showTips: '',
				current: 0,
				Phone: '',
				Email: '',
				formData: {},
				langInfo: null,
				showDataInfo: null, //包含手机号校验正则
			};
		},
		watch: {
			current() {
				this.showTips = '';
			}
		},
		created() {
			this.init();
		},
		methods: {
			init() {
				if (localStorage.getItem('NationName')) {
					this.formData.Nation = localStorage.getItem('NationName');
					this.langInfo = this.setLang();
				} else {
					this.$router.replace('/index')
				}
			},
			handClick() {
				if (!this.current) {
					/**
					 * 邮箱
					 * */
					if (!this.Email) {
						this.showTips = this.langInfo.newLangInfo.loginModel.emailTips;
						return;
					}
					if (this.Email.length < 5) {
						this.showTips = this.langInfo.newLangInfo.loginModel.emailTips;
						return;
					}
					// const reg = /^([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
					const reg = /^([a-zA-Z0-9._-])+(([a-zA-Z0-9._-])|([._-][a-zA-Z0-9_])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
					if (!reg.test(this.Email)) {
						this.showTips = this.langInfo.newLangInfo.loginModel.emailTips;
						return;
					}
					this.formData.Email = this.Email;
					delete this.formData.Mobile;
				} else {
					/**
					 * 手机号
					 * */
					if (!this.Phone) {
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips;
						return;
					}

					if (this.formData.Nation === 'ID' && (this.Phone.length < 9 || this.Phone.length > 13)) { //ID 长度校验
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo.newLangInfo
							.loginModel.phoneTips2;
						return;
					}
					if (this.formData.Nation === 'TH' && this.Phone.length != 9) { //TH 长度校验
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo.newLangInfo
							.loginModel.phoneTips2;
						return;
					}
					if (this.formData.Nation === 'VN' && (this.Phone.length < 8 || this.Phone.length > 10)) { //VN
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo.newLangInfo
							.loginModel.phoneTips2;
						return;
					}

					if (this.formData.Nation === 'SG' && this.Phone.length != 8) { //VN
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo.newLangInfo
							.loginModel.phoneTips2;
						return;
					}
					if (this.formData.Nation === 'MY' && (this.Phone.length < 9 || this.Phone.length > 11)) { //MY
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo.newLangInfo
							.loginModel.phoneTips2;
						return;
					}
					if (this.formData.Nation === 'PH' && this.Phone.length != 10) { //PH
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo.newLangInfo
							.loginModel.phoneTips2;
						return;
					}

					if (!/^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/.test(this.Phone)) {
						this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo.newLangInfo
							.loginModel.phoneTips2;
						return;
					}

					this.formData.Mobile = this.showDataInfo.AreaCode + this.Phone;
					delete this.formData.Email;
				}

				this.axios.get(`/api/search/`, { 
						params: this.formData
					}).then((res) => {
						let resstr = res.data;
						if (parseInt(resstr.code) === 200) {
							if (resstr.msg[0].User_id) {
								Toast.loading({
									duration: 1000,
									message: 'sign in...',
									forbidClick: true,
									loadingType: 'spinner',
								});
								localStorage.setItem('NationName', resstr.msg[0].Nation);
								localStorage.setItem('LoginInfo', JSON.stringify(resstr.msg[0]));
								localStorage.setItem('LoginToken', resstr.access_token);
								document.cookie = `_at=${resstr.access_token};path=/`;
								if (this.ismask) {
									this.$emit('click');
								} else {
									this.$router.push('/info');
								}
							} else {
								this.showTips = this.langInfo.newLangInfo.loginModel.tips;
							}
						} else {
							// eslint-disable-next-line
							this.showTips = this.langInfo.newLangInfo.loginModel.phoneTips + "." + this.langInfo
								.newLangInfo.loginModel.phoneTips2;
						}
					})
					.catch((err) => {
						// eslint-disable-next-line
						console.log(err);
					});
			},
			handSign() {
				if (!this.ismask) {
					this.$emit('signup');
				} else {
					localStorage.setItem('registerShow', true)
					this.$router.push('/index');
				}
			},
			setLang() {
				let Info = langData.filter((res) => res.Nation == this.formData.Nation)[0];
				this.showDataInfo = List.filter((res) => res.Nation == this.formData.Nation)[0];
				return Info == undefined ? langData[0] : Info;
			}
		},
	};
</script>

<style lang="less" scoped>
	.register-model {

		.model-view {
			flex: 1;
			width: 70vw;

			.van-tabs__nav {
				background: #fff !important;
			}

			.item-value {
				border-radius: 24px;
				overflow: hidden;
				background: #F8F8F8;
				padding: 0px 24px;
				margin: 18px 0px;

				.value-input {
					width: 100%;
					height: 42px;
					line-height: 42px;
					background: #F8F8F8;
					border: 0px;
				}

				.tips {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 500;
					color: #333333;
					padding-right: 8px;
				}

				input {
					outline: none;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 500;
					color: #333333;
				}

				input::placeholder {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 500;
					color: #9FA0A2;
				}
			}

			.flex {
				padding-left: 6px;
				display: flex;
				align-items: center;
			}

			.model-tips {
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: red;
				padding: 0px 12px 12px;
			}

			.model-button {
				height: 36px;
				line-height: 36px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				color: #292929;
				text-align: center;
				border-radius: 24px;
				color: #ffffff;
				background: #245BD9;
				margin: 0px 24px;
			}

			.model-link {
				height: 36px;
				line-height: 36px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				text-align: center;
				margin: 12px 24px;
				text-decoration: underline;
			}
		}

		.mask {
			background: #ffffff;
			padding: 50px 24px;
			border-radius: 24px;
		}
	}
</style>
